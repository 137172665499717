<template>
  <div class="relative inline-block container mx-auto mt-7 py-7 bg-slate-200 rounded-lg text-left">
    <table class="w-full table-auto border-collapse border-0 border-slate-400">
      <tr v-if="to">
        <td class="font-bold max-w-content">TO:</td>
        <td>
          {{ to }}
        </td>
      </tr>
      <tr>
        <td class="font-bold max-w-content">SUBJECT:</td>
        <td>
          {{ subject }}
        </td>
      </tr>
    </table>
    <hr class="bg-white h-1 mt-5 mb-7" />
    <div class="body" v-html="body" />
  </div>
</template>

<script>
export default {
  name: 'EmailPreview',
  props: {
    to: {
      type: String,
    },
    subject: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
/* NOTE
  ::v-deep usage as a combinator has been deprecated. Use :deep(<inner-selector>) instead.
*/
.body :deep(p) {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.body :deep(a) {
  color: #3490dc;
  text-decoration: underline;
}
</style>
