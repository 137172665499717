import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToCamelCase } from '@thinknimble/tn-utils'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const CUSTOMER_LISTS_ENDPOINT = '/salesforce/gtm/customer-lists/'

export default class CustomerListAPI extends ModelAPI {
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FITERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */
  static FILTERS_MAP = {
    // NOTE: While we are being explicit with the Pagination and Sorting params, the inherited object includes them already
    ...CustomerListAPI.FILTERS_MAP,
    // Pagination
    page: ApiFilter.create({ key: 'page' }),
    pageSize: ApiFilter.create({ key: 'page_size' }),
    // Sorting
    ordering: ApiFilter.create({ key: 'ordering' }),
    // filters
    founderContactSfId: ApiFilter.create({ key: 'contact_id' }),
  }

  get client() {
    return AxiosClient
  }

  static ENDPOINT = CUSTOMER_LISTS_ENDPOINT

  retrieve(id) {
    const url = `${CUSTOMER_LISTS_ENDPOINT}${id}/`
    return this.client
      .get(url)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'CustomerListAPI.retrieve',
        }),
      )
  }

  update(id, data) {
    const url = `${CUSTOMER_LISTS_ENDPOINT}${id}/`
    return this.client
      .patch(url, this.cls.toAPI(data))
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'CustomerListAPI.update',
        }),
      )
  }

  submitToFriendlies(id) {
    const url = `${CUSTOMER_LISTS_ENDPOINT}${id}/submit-to-friendlies/`
    return this.client
      .post(url)
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'CustomerListAPI.submitToFriendlies',
        }),
      )
  }

  withOutstandingIntrosForUser() {
    const url = `${CUSTOMER_LISTS_ENDPOINT}with-outstanding-intros-for-user/`
    return this.client
      .get(url)
      .then((response) => response.data.map((x) => objectToCamelCase(x)))
      .catch(
        apiErrorHandler({
          apiName: 'CustomerListAPI.withOutstandingIntrosForUser',
        }),
      )
  }

  transferOutstandingIntrosToAdmin(id) {
    const url = `${CUSTOMER_LISTS_ENDPOINT}${id}/transfer-outstanding-intros-to-admin/`
    return this.client
      .post(url)
      .then((response) => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'CustomerListAPI.transferOutstandingIntrosToAdmin',
        }),
      )
  }
}
