<template>
  <div class="flex flex-col w-full items-baseline">
    <h1 class="w-full heading mb-12">{{ list?.name }}</h1>
    <LoadingSpinner v-if="loading" class="justify-center w-full" />
    <div v-else-if="!list.datetimeSalesforceHandoffCompleted" class="mt-14">
      <p>We are still processing the hand-off from Salesforce.</p>
      <p>This can take up to 15 minutes.</p>
    </div>
    <div v-else class="flex flex-col">
      <DefaultBanner v-if="list.statusIsSubmittedToFriendlies" class="mb-8">
        This list has been submitted to <span class="font-semibold">Friendlies</span> for
        introductions.
      </DefaultBanner>
      <DefaultBanner v-if="list.statusIsSubmittedToFounder && !list.founder.isAdmin" class="mb-8">
        This list has been submitted to <span class="font-semibold">Founder</span> for approval.
      </DefaultBanner>
      <div class="flex justify-end mb-3">
        <button
          class="px-3 py-2 rounded-lg border border-transparent hover:bg-gray-200 mr-4"
          @click.prevent="listSidebarShow = true"
        >
          <Cog6ToothIcon class="h-6 w-6" />
        </button>
      </div>
      <div class="m-2">
        <table class="mb-5 table-fixed border-collapse border-0 text-xs sm:text-sm">
          <thead>
            <tr>
              <th class="border-none"></th>
              <th
                class="border border-slate-300 cursor-pointer"
                @click="updateOrdering('contact__name')"
              >
                Name
                <span v-if="currentOrdering === 'contact__name'" class="sort-indicator">▲</span>
                <span v-if="currentOrdering === '-contact__name'" class="sort-indicator">▼</span>
                <span
                  v-if="currentOrdering !== '-contact__name' && currentOrdering !== 'contact__name'"
                  >▲▼</span
                >
              </th>
              <th
                class="border border-slate-300 cursor-pointer"
                @click="updateOrdering('contact__title')"
              >
                Title
                <span v-if="currentOrdering === 'contact__title'" class="sort-indicator">▲</span>
                <span v-if="currentOrdering === '-contact__title'" class="sort-indicator">▼</span>
                <span
                  v-if="
                    currentOrdering !== '-contact__title' && currentOrdering !== 'contact__title'
                  "
                  >▲▼</span
                >
              </th>
              <th class="border border-slate-300" @click="updateOrdering('contact__company_name')">
                Company Name
                <span v-if="currentOrdering === 'contact__company_name'" class="sort-indicator"
                  >▲</span
                >
                <span v-if="currentOrdering === '-contact__company_name'" class="sort-indicator"
                  >▼</span
                >
                <span
                  v-if="
                    currentOrdering !== '-contact__company_name' &&
                    currentOrdering !== 'contact__company_name'
                  "
                  >▲▼</span
                >
              </th>
              <th class="border border-slate-300">Mailboxes</th>
              <th class="border border-slate-300">Closest Internal Email</th>
              <th class="border border-slate-300" @click="updateOrdering('warmth_score')">
                Warmth to FRC
                <span v-if="currentOrdering === 'warmth_score'" class="sort-indicator">▲</span>
                <span v-if="currentOrdering === '-warmth_score'" class="sort-indicator">▼</span>
                <span
                  v-if="currentOrdering !== '-warmth_score' && currentOrdering !== 'warmth_score'"
                  >▲▼</span
                >
              </th>
              <th class="border border-slate-300">Last Outbound</th>
              <th class="border border-slate-300" @click="updateOrdering('friendly___name')">
                Assigned FRC Friendly
                <span v-if="currentOrdering === 'friendly___name'" class="sort-indicator">▲</span>
                <span v-if="currentOrdering === '-friendly___name'" class="sort-indicator">▼</span>
                <span
                  v-if="
                    currentOrdering !== '-friendly___name' && currentOrdering !== 'friendly___name'
                  "
                  >▲▼</span
                >
              </th>
              <th class="border border-slate-300">At Portfolio Company?</th>
              <th class="border border-slate-300 text-xs">Intro Requests in Last 2 Months</th>
              <th class="border border-slate-300" @click="updateOrdering('email_template___name')">
                Default Email Template
                <span v-if="currentOrdering === 'email_template___name'" class="sort-indicator"
                  >▲</span
                >
                <span v-if="currentOrdering === '-email_template___name'" class="sort-indicator"
                  >▼</span
                >
                <span
                  v-if="
                    currentOrdering !== '-email_template___name' &&
                    currentOrdering !== 'email_template___name'
                  "
                  >▲▼</span
                >
              </th>
              <th
                class="border border-slate-300 cursor-pointer"
                @click="updateOrdering('salesforce_gtm_customer_list_member__stage')"
              >
                Stage
                <span
                  v-if="currentOrdering === 'salesforce_gtm_customer_list_member__stage'"
                  class="sort-indicator"
                  >▲</span
                >
                <span
                  v-if="currentOrdering === '-salesforce_gtm_customer_list_member__stage'"
                  class="sort-indicator"
                  >▼</span
                >
                <span
                  v-if="
                    currentOrdering !== '-salesforce_gtm_customer_list_member__stage' &&
                    currentOrdering !== 'salesforce_gtm_customer_list_member__stage'
                  "
                  >▲▼</span
                >
              </th>
              <th class="border border-slate-300">Founder Notes</th>
              <th class="border border-slate-300">Friendly Notes</th>
              <th class="border-none"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="o in opportunities.list"
              :key="o.id"
              :class="{
                'bg-red-100': !o.founderSelected && !excludeFounderRejected,
                'bg-blue-100': o.founderSelected && !excludeFounderRejected,
              }"
            >
              <td><CheckboxField v-model:value="checkboxes[o.id]" /></td>
              <td class="border border-slate-300 font-semibold">
                <a
                  v-if="o.contactLinkedinProfile"
                  :href="o.contactLinkedinProfile"
                  target="_blank"
                  class="text-cyan-500"
                  >{{ o.contactName }}</a
                >
                <template v-else>{{ o.contactName }}</template>
              </td>
              <td class="border border-slate-300">{{ o.contactTitle || 'N/A' }}</td>
              <td class="border border-slate-300">{{ o.contactCompanyName || 'N/A' }}</td>
              <td class="border border-slate-300">{{ o.contactMailboxes || 'N/A' }}</td>
              <td class="border border-slate-300">{{ o.contactClosestInternalEmail || 'N/A' }}</td>
              <td class="border border-slate-300">{{ o.contactWarmth || 'N/A' }}</td>
              <td class="border border-slate-300">
                {{
                  o.contactLastOutboundMoment !== null
                    ? o.contactLastOutboundMoment.format('MM/DD/YYYY')
                    : 'N/A'
                }}
              </td>
              <td class="border border-slate-300">
                <OpportunityFriendlySelector
                  :disabled="!list.isEditableByAdmin || !o.contactId"
                  :founder="list.founder"
                  :opportunity="o"
                  :friendlies="friendlies"
                  @opportunity-updated="handleOpportunityUpdated"
                />
              </td>
              <td class="border border-slate-300">
                {{
                  o.contactCompanyIsPortfolioCompany
                    ? 'Yes (' + o.contactCompanyPortfolioPointContactName + ')'
                    : 'No'
                }}
              </td>
              <td class="border border-slate-300">{{ o.contactGtmListsInLast2Months || '0' }}</td>
              <td class="border border-slate-300">{{ o.mainEmailTemplate?.name || 'N/A' }}</td>
              <td class="border border-slate-300">
                {{
                  o.salesforceGtmListMemberStage === 'Friendly Did Not Respond'
                    ? o.salesforceGtmListMemberStage +
                      ' - ' +
                      o.salesforceGtmListMemberStageFriendlyDidNotRespondByDateMoment.format(
                        'MM/DD/YYYY',
                      )
                    : o.salesforceGtmListMemberStage
                }}
              </td>
              <td class="border border-slate-300">
                <button
                  v-if="o.founderNotes"
                  class="text-cyan-500 font-semibold"
                  @click.prevent="openFounderNotesModal(o)"
                >
                  View
                </button>
              </td>
              <td class="border border-slate-300">
                <button
                  v-if="o.friendlyNotes"
                  class="text-cyan-500 font-semibold"
                  @click.prevent="openFriendlyNotesModal(o)"
                >
                  View
                </button>
              </td>
              <td class="border-none">
                <button
                  class="px-3 py-2 rounded-lg border border-transparent hover:bg-gray-200"
                  @click.prevent="copyToClipboard(o)"
                >
                  <ClipboardDocumentCheckIcon class="h-6 w-6" />
                </button>
                <button
                  class="px-3 py-2 rounded-lg border border-transparent hover:bg-orange-200"
                  @click.prevent="attemptDeleteOpportunityAndMember(o)"
                >
                  <TrashIcon class="h-6 w-6" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!opportunities.list.length" class="py-8">No Intros selected by Stakeholder.</div>
    </div>
  </div>
  <div
    class="flex justify-center"
    :class="{
      hidden: loading || !list.datetimeSalesforceHandoffCompleted || !opportunities.list.length,
    }"
  >
    <CollectionPagination
      :collection="opportunities"
      @is-loading-status-update="loading = $event"
      pluralItemName="intros"
    />
  </div>

  <Modal v-if="founderNotesModalShow" @close="founderNotesModalShow = false" classes="max-w-3xl">
    <h1 class="heading font-bold">Founder Notes.</h1>
    <div class="text-base text-left mb-6">
      <div>
        <a
          v-if="founderNotesModalOpportunity.contactLinkedinProfile"
          :href="founderNotesModalOpportunity.contactLinkedinProfile"
          target="_blank"
          class="text-cyan-500"
          >{{ founderNotesModalOpportunity.contactName }}</a
        >
        <template v-else>{{ founderNotesModalOpportunity.contactName }}</template>
      </div>
      <div>{{ founderNotesModalOpportunity.contactTitle }}</div>
      <div>{{ founderNotesModalOpportunity.contactCompanyName }}</div>
    </div>
    <div class="text-base text-left">
      <h2 class="underline mb-2">Notes:</h2>
      <TextAreaField :value="founderNotesModalOpportunity.founderNotes" disabled />
    </div>
  </Modal>

  <Modal v-if="friendlyNotesModalShow" @close="friendlyNotesModalShow = false" classes="max-w-3xl">
    <h1 class="heading font-bold">Friendly Notes.</h1>
    <div class="text-base text-left mb-6">
      <div>
        <a
          v-if="friendlyNotesModalOpportunity.contactLinkedinProfile"
          :href="friendlyNotesModalOpportunity.contactLinkedinProfile"
          target="_blank"
          class="text-cyan-500"
          >{{ friendlyNotesModalOpportunity.contactName }}</a
        >
        <template v-else>{{ friendlyNotesModalOpportunity.contactName }}</template>
      </div>
      <div>{{ friendlyNotesModalOpportunity.contactTitle }}</div>
      <div>{{ friendlyNotesModalOpportunity.contactCompanyName }}</div>
      <div>Friendly: {{ friendlyNotesModalOpportunity.friendly.fullName }}</div>
    </div>
    <div class="text-base text-left">
      <h2 class="underline mb-2">Notes:</h2>
      <TextAreaField :value="friendlyNotesModalOpportunity.friendlyNotes" disabled />
    </div>
  </Modal>

  <Sidebar v-if="listSidebarShow" @close="listSidebarShow = false">
    <h3 class="text-lg font-semibold border-b py-4 mb-8">Customer List Options</h3>
    <div class="rounded-lg bg-transparent hover:bg-gray-100">
      <Popper hover placement="left-start" class="w-full">
        <button
          class="w-full flex items-center text-base font-medium text-gray-900 rounded-lg hover:bg-gray-100 group py-2 px-1"
          @click.prevent="toggleFounderDeclined"
        >
          <EyeSlashIcon v-if="excludeFounderRejected" class="h-6 w-6" />
          <EyeIcon v-else class="h-6 w-6" />
          <span class="ml-2">{{
            excludeFounderRejected ? 'Show Founder Declined' : 'Hide Founder Declined'
          }}</span>
        </button>
        <template #content>
          <div class="bg-white rounded-lg p-5 border">
            Also display intros that have been declined by the founder.
          </div>
        </template>
      </Popper>
    </div>
    <div class="rounded-lg bg-transparent hover:bg-gray-100">
      <Popper
        :disabled="list.statusIsSubmittedToFounder"
        hover
        placement="left-start"
        class="w-full"
      >
        <button
          class="w-full flex items-center text-base font-medium text-gray-900 rounded-lg hover:bg-gray-100 group py-2 px-1 disabled:cursor-not-allowed"
          :disabled="!list.statusIsSubmittedToFounder"
          @click.prevent="attemptRedoHandoff"
        >
          <ArrowPathIcon class="h-6 w-6" />
          <span class="ml-2">Re-do SFDC Handoff</span>
        </button>
        <template #content>
          <div class="bg-white rounded-lg p-5 border">
            Only available when status is
            <span class="font-semibold italic">Submitted to Founder</span>.
          </div>
        </template>
      </Popper>
    </div>
    <div class="rounded-lg bg-transparent hover:bg-gray-100">
      <Popper :disabled="checkedIds.length" hover placement="left-start" class="w-full">
        <button
          class="w-full flex items-center text-base font-medium text-gray-900 rounded-lg hover:bg-gray-100 group py-2 px-1 disabled:cursor-not-allowed"
          :disabled="!checkedIds.length"
          @click.prevent="emailTemplateSelectionModalShow = true"
        >
          <CircleStackIcon class="h-6 w-6" />
          <span class="ml-2"
            >Set {{ checkedIds.length ? checkedIds.length : '' }} Email
            {{ checkedIds.length === 1 ? 'Template' : 'Templates' }}</span
          >
        </button>
        <template #content>
          <div class="bg-white rounded-lg p-5 border">Must select at least one intro.</div>
        </template>
      </Popper>
    </div>
    <button
      @click.prevent="exportTableData(false)"
      class="w-full flex items-center text-base font-medium text-gray-900 rounded-lg hover:bg-gray-100 group p-2 pl-4"
    >
      <TableCellsIcon class="h-6 w-6" />
      <span class="ml-2">Export All Table Data</span>
    </button>
    <button
      @click.prevent="exportTableData(true)"
      class="w-full flex items-center text-base font-medium text-gray-900 rounded-lg hover:bg-gray-100 group p-2 pl-4"
    >
      <TableCellsIcon class="h-6 w-6" />
      <span class="ml-2">Export Only Founder Selected</span>
    </button>
    <button
      @click.prevent="founderUrlToClipboard"
      class="w-full flex items-center text-base font-medium text-gray-900 rounded-lg hover:bg-gray-100 group p-2 pl-4"
    >
      <LinkIcon class="h-6 w-6" />
      <span class="ml-2">Founder List URL</span>
    </button>
  </Sidebar>

  <EmailTemplateSelectionModal
    v-if="emailTemplateSelectionModalShow"
    headerText="Select Default Template."
    :templates="adminTemplates.list"
    @select="attemptUpdateEmailTemplateForSelectedOpportunities"
    @close="emailTemplateSelectionModalShow = false"
  />
  <FriendlySelectionModal
    v-if="friendlySelectionModalShow"
    :friendlies="friendlies"
    @select="attemptUpdateFriendlyForSelectedOpportunities"
    @close="friendlySelectionModalShow = false"
  />
</template>

<script>
import { errorToast, successToast } from '@/services/toastify'
import CustomerList, { constants } from '@/services/customerLists'
import Opportunity from '@/services/opportunities'
import OpportunityEmailTemplate from '@/services/opportunityEmailTemplates'
import User from '@/services/users'
import {
  ArrowPathIcon,
  ArrowsRightLeftIcon,
  CircleStackIcon,
  ClipboardDocumentCheckIcon,
  Cog6ToothIcon,
  LinkIcon,
  TableCellsIcon,
  TrashIcon,
  UserCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/vue/24/outline'
import LoadingSpinner from '@/components/LoadingSpinner'
import OpportunityFriendlySelector from '@/components/inputs/OpportunityFriendlySelector'
import Button from '@/components/inputs/Button'
import DefaultBanner from '@/components/banners/DefaultBanner'
import Modal from '@/components/modals/Modal'
import TextAreaField from '@/components/inputs/TextAreaField'
import CollectionPagination from '@/components/inputs/CollectionPagination'
import Sidebar from '@/components/sidebars/Sidebar'
import EmailTemplateSelectionModal from '@/components/modals/EmailTemplateSelectionModal'
import FriendlySelectionModal from '@/components/modals/FriendlySelectionModal'
import CheckboxField from '@/components/inputs/CheckboxField'

const checkboxDataHandler = {
  get: function (target, name) {
    return name in target ? target[name] : false
  },
}

export default {
  name: 'AdminCustomerListShow',
  components: {
    ArrowPathIcon,
    ArrowsRightLeftIcon,
    CircleStackIcon,
    ClipboardDocumentCheckIcon,
    Cog6ToothIcon,
    LinkIcon,
    TableCellsIcon,
    TrashIcon,
    UserCircleIcon,
    EyeIcon,
    EyeSlashIcon,
    Button,
    CollectionPagination,
    DefaultBanner,
    EmailTemplateSelectionModal,
    FriendlySelectionModal,
    LoadingSpinner,
    Modal,
    OpportunityFriendlySelector,
    Sidebar,
    TextAreaField,
    CheckboxField,
  },
  data() {
    return {
      loading: true,
      attemptingUpdate: false,
      list: null,
      friendlies: [],
      excludeFounderRejected: this.$route.query.exclude_founder_rejected !== 'false',
      adminTemplates: OpportunityEmailTemplate.createCollection({
        pagination: { size: 99, page: 1 },
        filters: { ordering: 'name', isAdminTemplate: true },
      }),
      opportunities: Opportunity.createCollection({
        pagination: { size: this.$route.query.pageSize || 20, page: this.$route.query.page || 1 },
        filters: {
          listId: this.$route.params.id,
          excludeFounderRejected: this.$route.query.exclude_founder_rejected || true,
          ordering: this.$route.query.ordering || 'contact__name',
        },
      }),
      founderNotesModalShow: false,
      friendlyNotesModalShow: false,
      founderNotesModalOpportunity: null,
      friendlyNotesModalOpportunity: null,
      listSidebarShow: false,
      emailTemplateSelectionModalShow: false,
      friendlySelectionModalShow: false,
      checkboxes: new Proxy({}, checkboxDataHandler),
    }
  },
  async created() {
    this.excludeFounderRejected = this.$route.query.exclude_founder_rejected !== 'false'
    await this.retrieveList()
    this.refreshOpportunities()
    await this.retrieveFriendlies()
    this.loading = false
  },
  methods: {
    async retrieveList() {
      this.list = await CustomerList.api.retrieve(this.$route.params.id)
    },
    async retrieveFriendlies() {
      this.friendlies = await User.api.listFriendlies()
    },
    refreshOpportunities() {
      this.loading = true
      this.opportunities.filters.excludeFounderRejected = this.excludeFounderRejected
      this.opportunities.refresh().finally(() => {
        this.loading = false
      })
    },
    handleOpportunityUpdated(opportunity) {
      this.opportunities.list = this.opportunities.list.map((o) =>
        o.id === opportunity.id ? opportunity : o,
      )
    },
    async handleSubmitToFriendles() {
      if (!confirm('Confirm action: Submit list to friendlies.')) return
      if (this.opportunities.list.some((o) => !o.mainEmailTemplate))
        return errorToast(
          'Please assign a Default Email Template to each intro before submitting the list to Friendlies.',
        )
      this.attemptingUpdate = true
      this.list = await CustomerList.api.submitToFriendlies(this.list.id)
      this.attemptingUpdate = false
      successToast(
        `SalesForce CustomerList status updated to:\n${constants.SUBMITTED_TO_FRIENDLIES}.\n\nNOTE: Email reminders to FRC Friendlies are sent on Tuesdays and Fridays.`,
      )
    },
    async attemptUpdateEmailTemplateForSelectedOpportunities(template) {
      if (!confirm(`Confirm action: Set "${template.name}" as default template for selected.`))
        return
      await Opportunity.api.bulkUpdate(this.checkedIds, { mainEmailTemplateId: template.id })
      window.location.reload()
    },
    async attemptUpdateFriendlyForSelectedOpportunities(friendly) {
      if (!confirm(`Confirm action: Set "${friendly.fullName}" as Friendly for selected.`)) return
      await Opportunity.api.bulkUpdate(this.checkedIds, { friendlyId: friendly.id })
      window.location.reload()
    },
    attemptRedoHandoff() {
      if (!confirm('Confirm action: Re-do SFDC Handoff.')) return
      CustomerList.api
        .update(this.list.id, { datetimeSalesforceHandoffCompleted: null })
        .then(() => {
          successToast('Queued SFDC handoff. This list will be temporarily unavailable.')
          setTimeout(() => window.location.reload(), 6000)
        })
    },
    exportTableData(excludeFounderRejected = false) {
      window.open(
        `/api/salesforce/gtm/customer-lists/${this.list.id}/export/?exclude_founder_rejected=${excludeFounderRejected}`,
        '_blank',
      )
    },
    founderUrlToClipboard() {
      const founderRoute = this.$router.resolve({
        name: 'FounderCustomerListShow',
        params: { id: this.$route.params.id },
      })
      const url = window.location.origin + founderRoute.href
      navigator.clipboard
        .writeText(url)
        .then(() => successToast('Copied Founder List URL to clipboard'))
        .catch((error) => console.error('Copy failed', error))
    },
    copyToClipboard(opportunity) {
      const copyText = `${opportunity.contactName}, ${opportunity.contactTitle}${
        opportunity.contactCompanyName ? ' at ' + opportunity.contactCompanyName : ''
      }${opportunity.contactLinkedinProfile ? ' - ' + opportunity.contactLinkedinProfile : ''}`
      navigator.clipboard
        .writeText(copyText)
        .then(() => successToast('Copied to clipboard:\n' + copyText))
        .catch((error) => errorToast('Copy failed' + error))
    },
    attemptDeleteOpportunityAndMember(opportunity) {
      if (
        !confirm(
          `Are you sure you want to delete ${opportunity.name} from this list? This will remove the list member from the SalesForce list and we will not do outreach for this particular intro.`,
        )
      )
        return
      Opportunity.api
        .deleteOpportunityAndListMember(opportunity.id)
        .then(() => window.location.reload())
    },
    attemptTransferOutstandingToAdmin() {
      if (!confirm(`Confirm action: Transfer outstanding intros to List Admin.`)) return
      CustomerList.api
        .transferOutstandingIntrosToAdmin(this.list.id)
        .then(({ transferred }) => successToast(`Transferred ${transferred} intros to List Admin`))
    },
    openFounderNotesModal(opportunity) {
      this.founderNotesModalOpportunity = new Opportunity(JSON.parse(JSON.stringify(opportunity)))
      this.founderNotesModalShow = true
    },
    openFriendlyNotesModal(opportunity) {
      this.friendlyNotesModalOpportunity = new Opportunity(JSON.parse(JSON.stringify(opportunity)))
      this.friendlyNotesModalShow = true
    },
    updateOrdering(ordering) {
      const currentOrdering = this.$route.query.ordering || 'contact__name'
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: 1,
          pageSize: this.opportunities.pagination.size,
          ordering: currentOrdering === ordering ? '-' + ordering : ordering,
        },
      })
    },
    setPaginationQueryParams() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: this.opportunities.pagination.page,
          pageSize: this.opportunities.pagination.size,
          ordering: this.opportunities.filters.ordering,
        },
      })
    },
    toggleFounderDeclined() {
      this.excludeFounderRejected = !this.excludeFounderRejected
      this.$router
        .push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            exclude_founder_rejected: this.excludeFounderRejected ? 'true' : 'false',
            page: 1,
          },
        })
        .then(() => this.refreshOpportunities())
    },
  },
  computed: {
    submitToFriendliesIsDisabled() {
      return (
        this.attemptingUpdate || !this.opportunities.list.length || !this.list.isEditableByAdmin
      )
    },
    currentOrdering() {
      return this.$route.query.ordering || 'contact__name'
    },
    checkedIds() {
      return Object.entries(this.checkboxes)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler() {
        this.excludeFounderRejected = this.$route.query.exclude_founder_rejected !== 'false'
        this.loading = true
        this.opportunities.filters.ordering = this.$route.query.ordering || 'contact__name'
        this.opportunities.filters.excludeFounderRejected =
          this.$route.query.exclude_founder_rejected || true
        this.opportunities.pagination.page = this.$route.query.page || 1
        this.opportunities.pagination.size = this.$route.query.pageSize || 20
        this.opportunities.refresh().finally(() => {
          this.loading = false
        })
      },
    },
    'opportunities.pagination.page': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'opportunities.pagination.size': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'opportunities.filters.ordering': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'opportunities.filters.excludeFounderRejected': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
  },
}
</script>

<style scoped>
th,
td {
  padding: 0.5rem;
}
</style>
