<template>
  <Modal @close="$emit('close')" classes="max-w-3xl">
    <div class="flex flex-col h-full">
      <h1 class="heading font-bold">{{ headerText }}</h1>
      <VueMultiselect
        class="w-full"
        v-model="template"
        :options="templates"
        :close-on-select="true"
        :multiple="false"
        :showLabels="false"
        label="name"
        trackBy="id"
      />
      <EmailPreview v-if="template" :subject="template.subject" :body="template.body" />
      <div class="mt-10">
        <Button class="mr-2" @click.prevent="$router.push({ name: 'OpportunityEmailTemplateNew' })">
          Create Template
        </Button>
        <Button
          class="ml-2"
          primary
          :disabled="!template"
          @click.prevent="$emit('select', template)"
        >
          Select Template
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import Modal from '@/components/modals/Modal'
import EmailPreview from '@/components/EmailPreview'
import Button from '@/components/inputs/Button'

export default {
  name: 'EmailTemplateSelectionModal',
  components: {
    Modal,
    VueMultiselect,
    EmailPreview,
    Button,
  },
  props: {
    headerText: {
      type: String,
      default: 'Templates.',
    },
    templates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      template: null,
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
