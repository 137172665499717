<template>
  <div class="flex flex-col items-start mb-4 w-full">
    <label v-if="label" :for="`${label}-field`" class="font-bold">
      {{ label }}
    </label>
    <textarea
      :id="`${label}-field`"
      :placeholder="placeholder || ''"
      :disabled="disabled"
      spellcheck="false"
      v-model="val"
      @input="$emit('input', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus', $event)"
      class="input mt-2 h-32 p-4"
    />
    <ul v-if="errors.length">
      <li
        v-for="(error, index) in errors"
        :key="index"
        v-text="error.message"
        class="input--error"
      />
    </ul>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'TextAreaField',
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['blur', 'focus', 'input', 'update:value'],
  setup(props, context) {
    const val = computed({
      get: () => props.value,
      set: (value) => context.emit('update:value', value),
    })

    return { val }
  },
}
</script>
