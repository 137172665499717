export const CREATED = 'Created'
export const SUBMITTED_TO_FOUNDER = 'Submitted to Founder'
export const APPROVED_BY_FOUNDER = 'Approved by Founder'
export const SUBMITTED_TO_FRIENDLIES = 'Submitted to Friendlies'

// NOTE: these are written in order of UX flow
export const statuses = {
  CREATED,
  SUBMITTED_TO_FOUNDER,
  APPROVED_BY_FOUNDER,
  SUBMITTED_TO_FRIENDLIES,
}

export default {
  CREATED,
  SUBMITTED_TO_FOUNDER,
  APPROVED_BY_FOUNDER,
  SUBMITTED_TO_FRIENDLIES,
  statuses,
}
