<template>
  <Modal @close="$emit('close')" classes="max-w-3xl">
    <div class="flex flex-col h-full">
      <h1 class="heading font-bold">Assign Friendly.</h1>
      <VueMultiselect
        class="w-full"
        v-model="friendly"
        :options="friendlies"
        :close-on-select="true"
        :multiple="false"
        :showLabels="false"
        label="fullName"
        trackBy="id"
      />
      <div class="mt-10">
        <Button
          class="ml-2"
          primary
          :disabled="!friendly"
          @click.prevent="$emit('select', friendly)"
        >
          Assign
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import Modal from '@/components/modals/Modal'
import Button from '@/components/inputs/Button'

export default {
  name: 'FriendlySelectionModal',
  components: {
    Modal,
    VueMultiselect,
    Button,
  },
  props: {
    friendlies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      friendly: null,
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
