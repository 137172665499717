<template>
  <LoadingSpinner v-if="!friendlies.length" />
  <VueMultiselect
    v-else
    class="w-48"
    v-model="value"
    :options="friendlies"
    :close-on-select="true"
    :multiple="false"
    :showLabels="false"
    :disabled="disabled"
    label="fullName"
    trackBy="id"
    @select="(friendly) => attemptUpdateFriendlyId(friendly.id)"
    @remove="attemptUpdateFriendlyId(null)"
  />
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import Opportunity from '@/services/opportunities'
import User from '@/services/users'

import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'OpportunityFriendlySelector',
  components: { LoadingSpinner, VueMultiselect },
  props: {
    opportunity: {
      type: Opportunity,
      required: true,
    },
    founder: {
      type: User,
      required: true,
    },
    friendlies: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
    }
  },
  async created() {
    this.setValue()
  },
  methods: {
    setValue() {
      this.value = this.friendlies.find((f) => f.id === this.opportunity.friendly?.id)
    },
    async attemptUpdateFriendlyId(friendlyId) {
      const data = { friendlyId }
      const opportunity = await Opportunity.api.update(this.opportunity.id, data)
      this.$emit('opportunity-updated', opportunity)
    },
  },
  watch: {
    friendlies() {
      this.setValue()
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
input,
span {
  font-size: small;
}
[class^="multiselect"] {
  font-size: small;
}
</style>
