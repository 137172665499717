import Model, { fields } from '@thinknimble/tn-models'
import User from '../users'

import CustomerListAPI from './api'
import { statuses } from './constants'

export default class CustomerList extends Model {
  static api = CustomerListAPI.create(CustomerList)

  static id = new fields.CharField({ readOnly: true })
  static sfId = new fields.CharField()
  static name = new fields.CharField()
  static stage = new fields.CharField()
  static status = new fields.CharField()
  static founder = new fields.ModelField({ ModelClass: User })
  static datetimeSalesforceHandoffCompleted = new fields.CharField()
  static datetimeCreated = new fields.CharField({ readOnly: true })
  static createdByUserName = new fields.CharField({ readOnly: true })

  get statusIsSubmittedToFounder() {
    return this.status === statuses.SUBMITTED_TO_FOUNDER
  }

  get statusIsApprovedByFounder() {
    return this.status === statuses.APPROVED_BY_FOUNDER
  }

  get statusIsSubmittedToFriendlies() {
    return this.status === statuses.SUBMITTED_TO_FRIENDLIES
  }

  get isEditableByFounder() {
    return this.statusIsSubmittedToFounder
  }

  get isEditableByAdmin() {
    // only editable if not submitted to friendlies
    if (this.statusIsSubmittedToFriendlies) return false
    // if the founder is an admin, then the list is editable by an admin
    // even if the status has not been set to 'Approved By Founder'
    return this.statusIsApprovedByFounder || this.founder.isAdmin
  }

  get statusDisplayTextForFounder() {
    const statusToDisplayText = {
      [statuses.CREATED]: 'Created',
      [statuses.SUBMITTED_TO_FOUNDER]: 'Pending Review',
      [statuses.APPROVED_BY_FOUNDER]: 'Submitted to First Round',
      [statuses.SUBMITTED_TO_FRIENDLIES]: 'Submitted to First Round',
    }

    return statusToDisplayText[this.status]
  }
}
