<template>
  <div
    class="fixed flex z-40 top-0 left-0 w-screen h-screen overflow-auto bg-black/50"
    @click.self="$emit('close')"
  >
    <aside
      class="fixed top-0 right-0 z-40 w-80 h-screen transition-transform -translate-x-full sm:translate-x-0"
    >
      <div class="overflow-y-auto py-5 px-3 h-full bg-white border-l border-gray-200">
        <slot />
      </div>
      <div
        class="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white z-20 border-l border-gray-200"
      >
        <slot name="footer" />
      </div>
    </aside>
  </div>
</template>
